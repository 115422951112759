import React, { useState, useRef } from "react";
import {
  IconButton,
  Modal,
  TextField,
  Box,
} from "@mui/material";
import { LinkedIn, ReportGmailerrorredOutlined } from "@mui/icons-material";
import styled from "styled-components";
import { message, Spin } from "antd";
import "../styles/floatingIcons.css";
import Send from '../assets/send.svg';
import emailjs from '@emailjs/browser';

const SocialContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 8px;
  background: rgba(74, 203, 76, 0.33); /* Green background */
  border-radius: 0 8px 8px 0;
  z-index: 1000;

  @media (max-width: 768px) {
    top: auto;
    bottom: 10px;
    left: 10px;
    transform: none;
    flex-direction: row;
    border-radius: 8px;
    background: rgba(74, 203, 76, 0.33);
  }
`;

const StyledIconButton = styled(IconButton)`
  color: #51dc4e; /* Initial icon color */

  &:hover {
    color: #ffffff; /* Hover text color */
    background: #ffffff; /* Hover background color */
  }
`;

const style = {
  position: "absolute",
  top: "1.5rem",
  bottom: "0.5rem",
  left: "50%",
  maxHeight: "85%",
  transform: "translate(-50%, 0)",
  width: "40%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  scrollY: true,
  pt: 1,
  pl: 2,
  pr: 2,
  pb: 4,

  "@media (max-width: 600px)": {
    width: "90%",
    top: "1rem",
    bottom: "1rem",
    borderRadius: "4px",
  },
  "@media (min-width: 600px)": {
    width: "75%",
  },

  "@media (max-width: 350px)": {
    height: "70%",
  },
  
};

const FloatingSocialIcons = () => {
  const [reportModal, setReportModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });
  const [isPosting, setIsPosting] = useState(false);
  const form = useRef();
  const handleOpenModal = () => {
    setReportModal(true);
  };

  const handleCloseModal = () => {
    setReportModal(false);
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  // Handle submissions
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsPosting(true);
  
    try {
      await emailjs.sendForm('service_abqconm', 'template_6lg0fem', form.current, {
        publicKey: 'a2wOaIrPQQZPLskDK',
      });
      setFormData({ name: "", description: "" });
      e.target.reset();
    } catch (error) {
      message.error("HTTP Error sending Issue")
    } finally {
      handleCloseModal();
      setIsPosting(false);
    }
  };
  return (
    <>
      <SocialContainer>
        {!reportModal ? (
          <a
            onClick={handleOpenModal}
            className="icon"
            rel="noopener noreferrer"
            title="Report an issue"
            alt="Report an issue"
          >
            <StyledIconButton aria-label="LinkedIn">
              <ReportGmailerrorredOutlined />
            </StyledIconButton>
          </a>
        ) : (
          <div className="report_problem">
            <Modal open={reportModal} onClose={handleCloseModal}>
              <Box sx={style}>
                <button
                  onClick={handleCloseModal}
                  className="close__button-modal"
                >
                  &times;
                </button>

                <div className="modal__header">
                  <h2 className="modal__title">
                    Report any issues or concern.
                  </h2>
                  <span className="modal_subtitle">Help us be better.</span>
                </div>

                <form ref={form} onSubmit={handleSubmit}>
                  <div className="report__form">
                    <div className="user__form-div">
                      <TextField
                        label="Issue title"
                        variant="outlined"
                        color="primary"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        sx={{
                          font: "1.5rem",
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderWidth: "2px",
                              borderColor: "rgba(0,0,0,0.3)",
                              borderRadius: "0.5rem",
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="user__form-div">
                      <div className="contact__form-div contact__form-area">
                        <label className="contact__form-tag">Issue Description</label>
                        <textarea
                          name="project"
                          cols="30"
                          rows="10"
                          className="contact__form-input"
                          placeholder="Write Us Your Issue..."
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <button className="issue__button button--flex" type="submit">
                    {isPosting ? <Spin /> : "Send"}
                    <img src={Send} alt="send"className='button__icon' />
                  </button>
                </form>
              </Box>
            </Modal>
          </div>
        )}

        <a
          href="https://www.linkedin.com/company/jaza-capital-limited/"
          target="_blank"
          className="icon"
          rel="noopener noreferrer"
        >
          <StyledIconButton aria-label="LinkedIn">
            <LinkedIn />
          </StyledIconButton>
        </a>
      </SocialContainer>
    </>
  );
};

export default FloatingSocialIcons;
