import React from "react";
import "../styles/impacts.css";
const Impacts = () => {
  return (
    <section className="impacts impacts__section" id="impacts">
      <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
        Our Microfinance Impact
      </h2>
      <p className="mx-auto max-w-[700px] text-muted-foreground md:text-xl">
        See how our microfinance initiatives are transforming lives and
        communities around the world.
      </p>
      <div className="container">
        <div className="impacts__container container">
          <div className="impacts__content">
            <h3 className="text-lg font-semibold text-foreground">
              Empowering Women Entrepreneurs
            </h3>
            <p className="text-sm text-muted-foreground">
              Providing access to capital and business training for underserved
              women.
            </p>
          </div>
          <div className="impacts__content">
            <h3 className="text-lg font-semibold text-foreground">
              Financing Sustainable Agriculture
            </h3>
            <p className="text-sm text-muted-foreground">
              Helping smallholder farmers invest in climate-smart technologies.
            </p>
          </div>
          <div className="impacts__content">
            <h3 className="text-lg font-semibold text-foreground">
              Expanding Access to Education
            </h3>
            <p className="text-sm text-muted-foreground">
              Providing loans for students to pursue higher education.
            </p>
          </div>
          <div className="impacts__content">
            <h3 className="text-lg font-semibold text-foreground">
              Supporting Small Business Growth
            </h3>
            <p className="text-sm text-muted-foreground">
              Offering loans and mentorship to local entrepreneurs.
            </p>
          </div>
          <div className="impacts__content">
            <h3 className="text-lg font-semibold text-foreground">
              Improving Financial Inclusion
            </h3>
            <p className="text-sm text-muted-foreground">
              Bringing banking services to underserved communities.
            </p>
          </div>
          <div className="impacts__content">
            <h3 className="text-lg font-semibold text-foreground">
              Empowering Rural Communities
            </h3>
            <p className="text-sm text-muted-foreground">
              Providing access to credit and financial services in remote areas.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Impacts;
