import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Homepage from './pages/Homepage';
import AboutUsPage from './pages/Aboutpage';
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import Servicespage from './pages/Servicespage';
import Contactpage from './pages/Contactpage';
import Impacts from './component/Impacts';

function App() {
  return (

    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Homepage/>}/>
      <Route path='/About' element={<AboutUsPage/>}/>
      <Route path='/Services' element={<Servicespage/>}/>
      <Route path='/Contact' element={<Contactpage/>}/>
      <Route path='/Impacts' element={<Impacts/>}/>
    </Routes>
    </BrowserRouter>
  );
}

export default App;
