import React, { useState } from "react";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import grocery from "../assets/grocery.jpg";
import "../styles/about.css";
import FloatingSocialIcons from "../component/FloatingSocialIcons";
import { CiCircleChevUp, CiCircleChevDown } from "react-icons/ci";
const Servicespage = () => {
  const [expanded, setExpanded] = useState({});

  const toggleParagraph = (index) => {
    setExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
  };
  const services = [
    {
      title: "Micro-Loans",
      description:
        "Empower your entrepreneurial journey with our Microloans. These small-scale loans are designed to provide the financial boost you need to start or expand your business, helping you turn your vision into reality.",
    },
    {
      title: "Financial Advisory",
      description:
        "Navigate your financial future with confidence through our Financial Advisory services. Our personalized financial advice is crafted to help businesses manage their finances effectively, ensuring sustainable growth and stability.",
    },
    {
      title: "Business Training",
      description:
        "Unlock your business potential with our comprehensive Business Training programs. Our workshops and training sessions are tailored to equip business owners with essential skills, strategies, and knowledge to succeed in today's competitive market.",
    },
    {
      title: "Networking Opportunities",
      description:
        "Foster growth and expand your horizons with our Networking Opportunities. We connect entrepreneurs with potential partners, suppliers, and mentors, creating a supportive ecosystem that encourages collaboration and success.",
    },
  ];
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div class="container">
        <div class="container_content">
          <div class="container_content_inner">
            <div class="title">
              <h1>Our Services</h1>
            </div>
            <div class="par">
              <p>
                At Jaza Capital, we strive to empower small and medium-sized
                enterprises small and medium-based businesses by providing
                accessible and transparent financial solutions. By offering
                timely and flexible capital, we enable entrepreneurs to seize
                growth opportunities, innovate, and take their enterprises to
                new heights.
              </p>
            </div>
            <div class="btns">
              {/* <button class='btns_more'> See more </button> */}
            </div>
          </div>
        </div>
        <div class="container_outer_img">
          <div class="img-inner">
            <img src={grocery} alt="" class="container_img" />
          </div>
        </div>
      </div>
      <div class="overlay"></div>

      <section className="p-3">
        <div className="services-container">
          <div className="row justify-content-center text-center mb-2 mb-lg-4">
            <div className="col-lg-8 col-xl-7">
              <span className="text-muted">Features</span>
              <h1 className="display-7">Our Services</h1>
              <p className="lead">
                We provide a range of financial solutions tailored for small and
                medium-sized businesses. Explore our services to find the best
                fit for your business needs.
              </p>
            </div>
          </div>
          <div className="row g-4 g-md-5">
            {services.map((service, index) => (
              <div className="col-md-6" key={index}>
                <div className="d-flex">
                  <div className="text-primary me-4">
                    <svg
                      className="bi bi-clipboard-check"
                      fill="#008000"
                      height="32"
                      viewBox="0 0 16 16"
                      width="32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                        fillRule="evenodd"
                      ></path>
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path>
                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path>
                    </svg>
                  </div>
                  <div className="service">
                    <h5 className="mb-2 mb-lg-3 fw-bold">{service.title}</h5>
                    <p
                      className={`${
                        expanded[index] ? "d-block" : "d-none"
                      } d-md-block`}
                    >
                      {service.description}
                    </p>
                    <button
                      className="btn btn-link d-block d-md-none text-decoration-none"
                      onClick={() => toggleParagraph(index)}
                    >
                      {expanded[index] ? (
                        <CiCircleChevUp style={{ color: "#008000" }} />
                      ) : (
                        <CiCircleChevDown style={{ color: "#008000" }} />
                      )}
                    </button>
                    {/* <p
                className={`${
                  expanded[index] ? "d-block" : "d-none"
                } d-md-none`}
              >
                {service.description}
              </p> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* <Impact/> */}

      <section class="py-5">
        <div class="testimony-container">
          <div class="row justify-content-center text-center mb-2 mb-lg-4">
            <div class="col-12 col-lg-8 col-xxl-7 text-center mx-auto">
              <span class="text-muted">Raving Customers</span>
              <h1 class="display-7">Our Testimonials</h1>
              <p class="lead">
                Discover how our services have transformed the lives and
                businesses of our clients. Hear directly from those who have
                experienced our commitment to empowering growth and success
                through our tailored financial solutions.
              </p>
            </div>
          </div>
          <div class="row py-4">
            <div class="col-md-4 text-center">
              <div class="bg-light p-3 p-lg-5 mb-4">
                <div class="text-dark mb-2">
                  <svg
                    class="bi bi-quote"
                    fill="currentColor"
                    height="48"
                    viewbox="0 0 16 16"
                    width="48"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"></path>
                  </svg>
                </div>
                <p>
                  For my business, Jaza has really changed everything. For the
                  past year, I have been a happy customer, and the financing has
                  significantly scaled my operations
                </p>
              </div>
              <div class="mb-5 mb-lg-0">
                <img
                  alt=""
                  class="rounded-circle"
                  height="96"
                  src="https://freefrontend.dev/assets/square-small.png"
                  width="96"
                />
                <h5 class="fw-bold mt-4">Bosco Makiti (2023)</h5>
                <div class="text-muted">Graphic Designer</div>
              </div>
            </div>
            <div class="col-md-4 text-center">
              <div
                className="text-white p-3 p-lg-5 mb-4"
                style={{ backgroundColor: "#008000" }}
              >
                <div class="text-white mb-2">
                  <svg
                    class="bi bi-quote"
                    fill="currentColor"
                    height="48"
                    viewbox="0 0 16 16"
                    width="48"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"></path>
                  </svg>
                </div>
                <p>
                  I have been a customer at Jaza for 3 years. Jaza has helped me
                  grow and develop my hair salon. I highly appreciate their
                  support.
                </p>
              </div>
              <div class="mb-5 mb-lg-0">
                <img
                  alt=""
                  class="rounded-circle"
                  height="96"
                  src="https://freefrontend.dev/assets/square-small.png"
                  width="96"
                />
                <h5 class="fw-bold mt-4">Minoo Mutei (2024)</h5>
                <div class="text-muted">Hairdresser</div>
              </div>
            </div>
            <div class="col-md-4 text-center">
              <div class="bg-light p-3 p-lg-5 mb-4">
                <div class="text-dark mb-2">
                  <svg
                    class="bi bi-quote"
                    fill="currentColor"
                    height="48"
                    viewbox="0 0 16 16"
                    width="48"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"></path>
                  </svg>
                </div>
                <p>
                  I’ve been with Jaza Capital Microfinance for 2 years now,
                  financing my butchery, and I couldn’t be happier! My business
                  has thrived through their help. Grateful for a reliable
                  partner like Jaza Capital!
                </p>
              </div>
              <div class="mb-5 mb-lg-0">
                <img
                  alt=""
                  class="rounded-circle"
                  height="96"
                  src="https://freefrontend.dev/assets/square-small.png"
                  width="96"
                />
                <h5 class="fw-bold mt-4">Godfrey Otieno (2023)</h5>
                <div class="text-muted">Butcher</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FloatingSocialIcons />
      <Footer />
    </div>
  );
};

export default Servicespage;
